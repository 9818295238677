/*
This is where you write custom SASS
*/

::placeholder {
    font-family: 'brother-1816', sans-serif;
}

.form-control {
    font-family: 'brother-1816', sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.15em;
}

.text-initial {
    text-transform: initial;
}

.backdrop-blur {
  background-color: rgba(233, 233, 233, 0.9);
}

@supports ((-webkit-backdrop-filter: blur(50px)) or (backdrop-filter: blur(50px))) {
  .backdrop-blur {
    background: rgba(245,245,245,0.75);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
  }
}

//interior custom scss
body.interior {
	section[id*="interiorBanner"] {
		margin-top: 200px;
		height: 615px;
		min-height: 400px;

		@media screen and (max-width: 1600px) {
			& {
				height: 40vw;
			}
		}

		@media screen and (max-width: 992px) {
			margin-top: 150px;
		}

		&.page-type-3 {
			.card {
				padding: 60px 55px;
				background: linear-gradient(#5085A8 0%, #3B6E91 100%);

				p.h3 {
					text-transform: uppercase;
					color: #fff;
				}

				[id*="HeaderInfoDiv"] {
					font-size: 18px;
					font-weight: 300;
					color: #fff;
					line-height: 30px;
				}

				label {
					display: none;
				}
			}
		}
		// &.page-type-26 {
		//     .page-content .container {
		//         max-width: 960px;
		//     }
		// }
	}

	body[data-ajax-transitions="true"]
	#ajax-loading-screen[data-method="standard"]
	.loading-icon {
		display: block;
		opacity: 1;
		transition: 0.3s ease;
	}

	#ajax-loading-screen {
		background-color: #ffffff;
		width: 100%;
		height: 100%;
		position: fixed;
		transition: 0.3s ease;
		top: 0;
		left: 0;
		display: none;
		z-index: 1000000000;
	}

	.loading-icon.spin,
	.portfolio-loading.spin,
	.pp_loaderIcon.spin,
	.default-loading-icon.spin {
		animation: .3s ease 0s normal none
	}

	#ajax-loading-screen .loading-icon {
		background-repeat: no-repeat;
		background-position: center center;
		display: block;
		position: absolute;
		opacity: 0;
		top: 50%;
		left: 0;
		right: 0;
	}

	@keyframes fade_in_logo {
		0% {
			transform: scale(.8);
			opacity: .8;
		}

		50% {
			transform: scale(.9);
			opacity: .9;
		}

		100% {
			transform: scale(1);
			opacity: 1;
		}
	}

	.ctn-preloader {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		cursor: default;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 9000;
	}

	.ctn-preloader .animation-preloader {
		z-index: 1000;
	}

	.ctn-preloader .animation-preloader .spinner {
		-webkit-animation: spinner 1s infinite linear;
		animation: spinner 1s infinite linear;
		border-radius: 50%;
		border: 5px solid rgba(99, 102, 151, 0.2);
		border-top-color: $primary;
		height: 65px;
		margin: 0 auto 3.5em auto;
		width: 65px;
	}

	.ctn-preloader.dark .animation-preloader .spinner {
		border-color: rgba(99, 102, 151, 0.2);
		border-top-color: $primary;
	}

	.ctn-preloader .loader {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: 0;
		z-index: 1;
		pointer-events: none;
	}

	.ctn-preloader .loader .row {
		height: 100%;
	}

	.ctn-preloader .loader .loader-section {
		padding: 0px;
	}

	.ctn-preloader.loaded .animation-preloader {
		opacity: 0;
		-webkit-transition: 0.3s ease-out;
		-o-transition: 0.3s ease-out;
		transition: 0.3s ease-out;
	}

	.ctn-preloader.loaded .loader-section .bg {
		width: 0;
		-webkit-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
		-o-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
		transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
	}

	@-webkit-keyframes spinner {
		to {
			-webkit-transform: rotateZ(360deg);
			transform: rotateZ(360deg);
		}
	}

	@keyframes spinner {
		to {
			-webkit-transform: rotateZ(360deg);
			transform: rotateZ(360deg);
		}
	}

	@media screen and (max-width: 767px) {
		.ctn-preloader .animation-preloader .spinner {
			height: 65px;
			width: 65px;
		}
	}

	@media screen and (max-width: 500px) {
		.ctn-preloader .animation-preloader .spinner {
			height: 65px;
			width: 65px;
		}
	}

	@media (max-width: 1200px) {
		html .panel-image video {
			transform: translate3d(-50%,-40%,0);
		}
	}
}
