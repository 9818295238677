section.hero-section {
    min-height: 250px;
    height: 930px;
    // @media screen and (max-width: 1600px) {
    //     & {
    //         height: 700px;
    //     }
    // }
    // @media screen and (max-width: 1199px) {
    //     & {
    //         height: 105vw;
    //     }
    // }
}

/* <!-- Video Banner --> */

.panel-video-container {
    position: relative;
}

.panel-video, .panel-video-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    height: 100%;
    box-sizing: content-box;
    overflow: hidden
}

.panel-video video {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%,-50%,0);
    -moz-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0)
}

/* <!-- END --> */