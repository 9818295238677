section.tagline-section {
    @media screen and (max-width: 1300px) {
        [class*="btn"] {
            max-width: 265px !important;
        }
    }

    @media screen and (max-width: 576px) {
        .contact-info-col {
            margin-right: 4vw;
        }
    }

    .btn-ghost {
        border: 1px solid $primary !important;
        background: none !important;
    }

    .office-headers {
        margin-bottom: 20px;

        span {
            color: $primary;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: .2em;
            text-transform: uppercase;
            transition: .2s !important;
            display: inline-block;
            font-family: 'brother-1816',sans-serif;
            margin-right: 30px;
        }

        a {
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            transition: .2s ease !important;
            display: inline-block;
            font-family: 'brother-1816', sans-serif;
            margin-right: 30px;

            &.active {
                color: $secondary;

                &:hover {
                    color: $secondary;
                }
            }

            &:not(.active) {
                color: rgba(175,207,233,0.5);

                &:hover {
                    color: $secondary;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .office-addresses {
        margin-bottom: 40px;

        span[data-office-num] {
            line-height: 1.3;
            font-size: 28px;
            letter-spacing: 0.02em;
            color: $white;
            transition: .7s !important;
            visibility: hidden;
            opacity: 0;
            position: relative;
            top: 10px;

            &.active {
                & {
                    visibility: visible;
                    opacity: 1;
                    top: 0px;
                    display: block;
                }
            }

            &.active {
                span {
                    display: block !important;
                }
            }

            span {
                display: none !important;
            }
        }
    }



    .tagline {
        font-size: 57px;
        font-family: 'ivypresto-headline', serif;

        @include media-breakpoint-down(xl) {
            & {
                font-size: 47px;
            }
        }

        @include media-breakpoint-down(sm) {
            & {
                font-size: 40px;
            }
        }

        @include media-breakpoint-down(xs) {
            & {
                font-size: 31px;
            }
        }
    }
}
