
section.services-section {
    @include media-breakpoint-up(lg) {
        & {
            padding-top: 260px;
            padding-bottom: 260px;
        }
    }

    @include media-breakpoint-down(md) {
        & {
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }

    .service-headers a {
        color: #83817F;
        cursor: pointer;
        display: block;
        text-transform: uppercase;
        font-size: 18px;
        letter-spacing: 0.15em;
        font-family: 'brother-1816', sans-serif;
        margin-bottom: 30px;

        &.active {
            color: #242220;
        }

        @include media-breakpoint-down(md) {
            & {
                margin-bottom: 15px;
            }
        }
    }

    .service-descriptions {
        div[data-service-description-num] {
            position: relative;
            transition: .7s ease !important;
            visibility: hidden;
            opacity: 0;
            top: 20px;

            &.active {
                transition: .7s ease !important;
                visibility: visible;
                opacity: 1;
                top: 0px;

                p, span {
                    display: block !important;
                }
            }

            span {
                display: none !important;
            }

            p {
                display: none !important;
                color: #343A40;
                font-size: 20px;
                font-weight: 300;
                line-height: 34px;
            }
        }
    }

    span.btn-wrap {
        .btn {
            max-width: 245px !important;
            width: 100%;

            @include media-breakpoint-down(lg) {
                & {
                    max-width: 230px !important;
                }
            }
        }

        .btn-ghost {
            border: 1px solid $primary !important;
            background: $gray-900;
            background: $white;
        }
    }

    &.no-testimonials {
        background: #34302a;
        padding: 100px 0px !important;

        p.display-3 {
            color: #fff !important;
        }

        .service-headers a {
            color: #fff !important;

            &.active {
                color: darken(#fff, 20%) !important;
            }
        }

        .service-descriptions {
            p {
                color: #fff !important;
            }
        }

        span.btn-wrap .btn-ghost {
            background: #34302a !important;
        }
    }
}
